<script setup>
import LayoutNoNav from "@/Layouts/LayoutNoNav.vue";
import ButtonDefault from "@/Components/Buttons/ButtonDefault.vue";
import {router} from "@inertiajs/vue3";
import IconChevronDoubleRight from "@/Components/Icons/IconChevronDoubleRight.vue";
import IconRefresh from "@/Components/Icons/IconRefresh.vue";
import Translation from "@/Components/Translation/Translation.vue";

const props = defineProps({
    hours: Object,
})

function formatTime(value) {
    // Ensure that the value is a string and pad it if necessary
    return String(value).padStart(2, '0');
}

function refresh() {
    //this route will trigger the check time middleware
    router.get(route('landing.index'));
}
</script>

<template>
    <LayoutNoNav>
        <template #header>
            <div class="flex justify-center">
                <h1 class="text-center text-brainkitGrayscale2"><Translation tkey="ui.time.title"/></h1>
            </div>
        </template>

        <p class="mb-6 text-center"><Translation tkey="ui.time.subtitle"/></p>
        <p class="text-center font-bold">
            {{ formatTime(hours.start.hour) }}:{{ formatTime(hours.start.minutes) }} -
            {{ formatTime(hours.end.hour) }}:{{ formatTime(hours.end.minutes) }}</p>

        <ButtonDefault
            :icon-right="IconRefresh"
            class="mt-6"
            @click="refresh()"
        >

            <Translation tkey="ui.word.refresh"/>

        </ButtonDefault>
    </LayoutNoNav>
</template>
